<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:65px;">
    <div>
      <Breadcrumbs />

      <v-text-field
        class="ml-3 mt-7"
        v-model="generalSearch"
        append-icon="mdi-magnify"
        :label="$t('general.search')"
        single-line
        hide-details
        dense
        filled
        clearable
        :loading="isSearching"
        @keydown="handleSearch"
        @click:clear="handleSearch"
        style="display:inline-block;min-width:400px;"
      ></v-text-field>
      
      <div class="top-right-fab">
        <v-btn
          v-if="userFunctions"
          :disabled="userFunctions.find(uf => uf.id == FUNCTIONS.SITE_REPORT_ALLOW_EDITION) == null"
          class="techno-green"
          fab
          @click="create" 
        >
          <v-icon color="white">mdi-plus</v-icon>
        </v-btn>
      </div>
    </div>

    <v-container fluid v-if="columns">

      <!-- PROJECT_STATUS -->
      <ProjectsDatatable v-for="n in PROJECT_STATUS" :key="n"
            :datatableName="$t(PROJECT_STATUS_TEXT[n])" 
            :color="PROJECT_STATUS_COLOR[n].text"
            :projectStatus="n"
            :expandedByDefault="true"
            :columns="columns"
            :generalSearch="search"
            @editReport="editProject"
            @onShowMap="onShowMap"
       />
    </v-container>

    <v-dialog v-model="showMap">
      <v-card>
        <v-card-title>
          {{ $t("reportInfo.site_address") }}
          <a v-if="marker" :href="'https://www.google.com/maps/dir/?api=1&destination=' + marker.position.toString().replace('(','').replace(')','')"  target="_blank" style="margin-left:15px;">{{ $t("report.get_directions") }}</a>
          <v-icon style="position:absolute;top:15px;right:15px;" @click="showMap = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <div id="map" style="height:75vh;width:100%;"></div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import ProjectService from "@/services/project.service";
import Breadcrumbs from "@/components/Breadcrumbs";
import ProjectsDatatable from "@/components/ProjectsDatatable"
import { FUNCTIONS,PROJECT_STATUS, PROJECT_STATUS_TEXT, PROJECT_STATUS_COLOR } from '@/enums'
import i18n from "@/i18n";

/*global google*/

export default {
  components: {
    Breadcrumbs,
    ProjectsDatatable
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      currentUser: state => state.currentUser,
      userFunctions: (state) => state.userFunctions
    }),
    ...mapGetters({
      hasAccessToProjects: 'hasAccessToProjects'
    }),
  },
  data() {
    return {
      PROJECT_STATUS_COLOR:PROJECT_STATUS_COLOR,
      PROJECT_STATUS:Object.fromEntries(Object.entries(PROJECT_STATUS).filter(([key]) => key !== "DELETED")),
      PROJECT_STATUS_TEXT:PROJECT_STATUS_TEXT,
      locale: this.$i18n.locale,
      FUNCTIONS: FUNCTIONS,
      selectedProject: null,
      showMap: false,
      map: null,
      marker: null,
      generalSearch: "",
      isSearching: false,
      search: "",
      columns: [
        {
          field: "formattedDealerName",
          title: i18n.t("filters.dealer"),
          widthGrow: 2,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedCreatedAt",
          title: i18n.t("report.created_at"),
          widthGrow: 1,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedProjectNumber",
          title: i18n.t("projects.project_number"),
          widthGrow: 1,
          headerSort: true,
          active: true,
        },
        {
          field: "formattedClient",
          title: i18n.t("reportInfo.client"),
          widthGrow: 2,
          headerSort: true,
          active: true,
          formatter: "html",
        },
        {
          field: "formattedSiteAddress",
          title: i18n.t("reportInfo.site_address"),
          widthGrow: 5,
          headerSort: true,
          active: false,
          formatter: "html",
        },
        {
          field: "actions",
          title: "",
          width: 70,
          height: 39,
          headerSort: false,
          active: true,
          hidden: true,
          formatter: "html",
          hozAlign: "center"
        },
      ]
    };
  },
  mounted() {
    console.log('Projects mounted')

    if (this.hasAccessToProjects) {
      this.$store.dispatch("setBreadcrumbs", [{ text: this.$t("projects.projects"), href: "/projects" },]);
    }
    else {
      this.showErrorMessage(this.$t('general.access_denied'));
      this.$router.push('/');
    }

    for (let column of this.columns) {
      column.active = true;
    }
  },
  created: function() {
    console.log('Projects created')
  },
  beforeDestroy() {
    console.log('Projects unmounted')
  },
  methods: {
    ...mapMutations({
      openToast: 'openToast',
      showErrorMessage: 'showErrorMessage'
    }),
    ...mapActions({
      logout: 'logout'
    }),
    async create() {
      let data = { userId: this.currentUser.id, locale: this.locale, projectOnly: true };
      let response = await ProjectService.createProject(data);
      if (response.status == 200) {
        this.$router.push('/project/' + response.data.projectId);
      }
      else {
        this.openToast({ message: response, duration: 15000, color: 'red'});
      }
    },
    editProject(project) {
      console.log('Edit project', project)
      this.selectProject(project);
    },
    selectProject(project) {
      console.log('Select project', project)
      this.$router.push("/project/" + project.id);
    },
    async onShowMap(project) {
      this.selectedProject = project;
      this.showMap = true;

      if (!this.map) {
        setTimeout(() => { 
          this.initMap();
         }, 250);
      }
      else {
        this.updateMap();
      }
    },
    // Initialize and add the map
    initMap() { // eslint-disable-line

      // Fallback geolocation is TP headquarter
      let point = { lat: 46.11118448049068, lng: -71.2913423841532 };

      let project = this.selectedProject;
      if (project.siteAddressLatitude != null && project.siteAddressLongitude != null) {
        point = { lat: project.siteAddressLatitude, lng: project.siteAddressLongitude };
      }
      
      this.initMapWithPoint(point);
    },
    initMapWithPoint(point) {
      let mapElement = document.getElementById("map");
      console.log('mapElement', mapElement)
      this.map = new google.maps.Map(mapElement, {
        zoom: 10,
        center: point,
        gestureHandling: 'greedy'
      });

      this.marker = new google.maps.Marker({
        position: point,
        map: this.map,
      });
    },
    async updateMap() {
        let point = { lat: this.selectedProject.siteAddressLatitude, lng: this.selectedProject.siteAddressLongitude };

        if (this.marker != null) {
          this.marker.setMap(null);
        }
 
        this.marker = new google.maps.Marker({
            position: point,
            map: this.map
        });
        this.map.setCenter(point);
    },
    handleSearch() {
      let scope = this;
      this.isSearching = true;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.clearSearch();
      }, 1000);
    },
    clearSearch() {
      this.isSearching = false;
      this.search = this.generalSearch;
    },
  },
};

</script>

<style>
.badge-right-top {
  position: absolute!important;
  right: 20px;
  top: 20px;
}
.inlineblock {
  display: inline-block;
}
.v-badge--tile .v-badge__badge {
  border-radius: 3px;
}
td.text-start {
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: normal !important;
}

#columns-menu {
  overflow:hidden; 
}

#filters-menu{
  overflow:hidden; 
}
#btn-add{
  background-color:#009f4d;
}
th, td {
  border-right: 1px solid #f1f1f1;
}
.v-breadcrumbs {
  display: inline-block;
}
</style>